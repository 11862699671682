import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../user/operations';


export const fetchAllPaidServices = createAsyncThunk(
    'paid-services/fetchAll',
    async (page, thunkApi) => {
        try {
            const response = await instance.get(`/paid-services`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const addPaidService = createAsyncThunk(
    'paid-services/add',
    async (body, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch user");
            }
            const result = await instance.post(`/paid-services`, body, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);
export const updatePaidService = createAsyncThunk(
    'paid-services/update',
    async (body, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch user");
            }
            const result = await instance.patch(`/paid-services`, body, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);
export const deletePaidService = createAsyncThunk(
    'paid-services/delete',
    async (id, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch user");
            }
            const result = await instance.delete(`/paid-services/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);