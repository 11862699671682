import { FooterStyled } from "./Footer.styled";
import { LoginModal } from "../../components/modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import { logoutUser } from "../../store/user/operations";
import { useDispatch, useSelector } from "react-redux";

const Footer = () => {
  const dispatch = useDispatch();
  const { role, _id } = useSelector((state) => state.auth.user);
  const handleLogout = () => {
    dispatch(logoutUser({ _id }));
  };

  return (
    <FooterStyled>
      <div className="flex items-center">
        <LoginModal />
        <h3 className="text-sm">КНП Здолбунівська ЦМЛ 2024</h3>
      </div>
      {(role === "ADMIN" || role === "MODERATOR") && (
        <Button
          onClick={handleLogout}
          sx={{
            textTransform: "none",
            fontFamily: "inherit",
            fontWeigth: "600",
            color: "#1E6D9A",
            border: "1px solid #0FA7B0",
            fontSize: "18px",
            padding: "2px",
          }}
        >
          Вихід
        </Button>
      )}
      <ToastContainer />
    </FooterStyled>
  );
};

export default Footer;
