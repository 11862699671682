import { createSlice } from '@reduxjs/toolkit'
import { fetchAllUsefulInfo, addUsefulInfo, deleteUsefulInfo } from './operations';

const initialState = {
    usefulInfo: [],
    loading: false,
    error: null,
    count: 0,
    totalPages: 0,
    currentPage: 1,
}

export const usefulInfoSlice = createSlice({
    name: 'usefulInfo',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(fetchAllUsefulInfo.fulfilled, (state, { payload }) => {
                state.usefulInfo = payload;
                state.loading = false;
                state.totalPages = Math.ceil(payload.count / 10);
                state.count = payload.count
            })
            .addCase(fetchAllUsefulInfo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllUsefulInfo.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(addUsefulInfo.fulfilled, (state, { payload }) => {
                state.usefulInfo.push(payload);
                state.loading = false;
            })
            .addCase(addUsefulInfo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addUsefulInfo.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(deleteUsefulInfo.fulfilled, (state, { payload }) => {
                state.usefulInfo = state.usefulInfo.filter((item) => item._id !== payload._id);
                state.loading = false;
            })
            .addCase(deleteUsefulInfo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteUsefulInfo.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
    },
})

export default usefulInfoSlice.reducer