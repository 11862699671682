import React, { Component } from "react";
import fore from "../../assets/images/404-page.jpg";

class ErrorBoundary extends Component {
    state = {
        hasError: false,
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        console.error("Error caught by Error Boundary:", error, info);
    }

    render() {
        if (this.state.hasError) {
            return <img className="w-full h-full object-cover" alt="404" src={fore} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
