import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createUser, deleteUser, loginUser } from "../../store/user/operations";
import { addDoctor, deleteDoctor } from "../../store/doctors/operations";
import { addArticle, deleteArticle } from "../../store/articles/operations";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { HiOutlineMail, HiOutlineLockClosed } from "react-icons/hi";
import { AiTwotoneDelete } from "react-icons/ai";
import { AiFillCheckCircle } from "react-icons/ai";
import { emailPattern, passwordPattern } from "../patterns";
import { useDispatch, useSelector } from "react-redux";
import { setAuthError } from "../../store/user/slice";
import { useEffect } from "react";
import {
  selectAuthError,
  selectAuthIsLoading,
} from "../../store/user/selectors";
import Message from "../message/message";
import { FormFields, InputWraper } from "./index.styled";
import { toast } from "react-toastify";
import { selectIsLoggedIn } from "../../store/user/selectors";
import { useMediaQuery } from "react-responsive";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { addDocument, deleteDocument } from "../../store/documents/operations";
import { useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";
import { addUsefulInfo, deleteUsefulInfo } from "../../store/info/operations";
import {
  addPaidService,
  deletePaidService,
} from "../../store/services/operations";

const errorToast = () =>
  toast.error("Помилка!", {
    position: "top-center",
  });
const successToast = () =>
  toast("Успішно!", {
    position: "top-center",
  });

export const LoginModal = () => {
  const dispatch = useDispatch();
  const isLogedIn = useSelector(selectIsLoggedIn);
  const schema = yup.object().shape({
    email: yup
      .string()
      .email()
      .matches(emailPattern, "Email must be valid")
      .min(3)
      .max(30)
      .required(),
    password: yup
      .string()
      .matches(passwordPattern, "The password must contain letters and numbers")
      .required(),
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isLoading = useSelector(selectAuthIsLoading);

  useEffect(() => {
    dispatch(setAuthError(null));
  }, [dispatch]);
  const initialValues = { email: "", password: "" };

  const handleSubmit = (values) => {
    dispatch(loginUser(values));
    handleClose();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const message = useSelector(selectAuthError);
  return (
    <>
      <span onClick={handleOpen} className="p-1">
        ©
      </span>
      {isLogedIn || (
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting, submitCount }) => {
            const hasEmailError =
              submitCount > 0 && touched.email && errors.email;
            const hasPasswordError =
              submitCount > 0 && touched.password && errors.password;
            const isFormSubmitted = submitCount > 0;
            return (
              <Form autoComplete="off">
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-6 bg-white">
                    <h2 className="flex self-center text-xl lg:text-5xl">
                      Вхід
                    </h2>
                    <button
                      type="button"
                      onClick={handleClose}
                      className="absolute top-2 right-3"
                    >
                      <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
                    </button>
                    <form
                      id="loginform"
                      className="flex flex-col gap-4 p-5 w-[300px]"
                      onSubmit={handleSubmit}
                    >
                      <FormFields>
                        <InputWraper>
                          <HiOutlineMail
                            className={`${
                              touched.email &&
                              (hasEmailError
                                ? "error"
                                : isFormSubmitted
                                ? "success"
                                : "")
                            }`}
                          />
                          {!hasEmailError &&
                            isFormSubmitted &&
                            touched.email && (
                              <AiFillCheckCircle className="valid" />
                            )}
                          <Field
                            type="text"
                            name="email"
                            required
                            placeholder="Email"
                            // autoComplete="off"
                            className={`input-field ${
                              touched.email &&
                              (hasEmailError
                                ? "error"
                                : isFormSubmitted
                                ? "success"
                                : "")
                            }`}
                          />
                          {hasEmailError && (
                            <ErrorMessage
                              className="error"
                              component="div"
                              name="email"
                            />
                          )}
                        </InputWraper>

                        <InputWraper>
                          {!hasPasswordError &&
                            isFormSubmitted &&
                            touched.password &&
                            !isSubmitting && (
                              <AiFillCheckCircle className="valid" />
                            )}
                          <HiOutlineLockClosed
                            className={`${
                              touched.password &&
                              (hasPasswordError
                                ? "error"
                                : isFormSubmitted
                                ? "success"
                                : "")
                            }`}
                          />
                          {!hasEmailError &&
                            isFormSubmitted &&
                            touched.email && (
                              <AiFillCheckCircle className="valid" />
                            )}
                          <Field
                            type="password"
                            name="password"
                            required
                            placeholder="Password"
                            // autoComplete="off"
                            className={`input-field ${
                              touched.password &&
                              (hasPasswordError
                                ? "error"
                                : isFormSubmitted
                                ? "success"
                                : "")
                            }`}
                          />
                          {hasPasswordError && (
                            <ErrorMessage
                              className="error"
                              component="div"
                              name="password"
                            />
                          )}
                        </InputWraper>
                      </FormFields>
                      <div className="self-center">
                        {isLoading ? (
                          <button
                            type="submit"
                            className="self-center pointer-events-none"
                          >
                            <div className="flex space-x-2 justify-center items-center">
                              <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                              <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                              <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                            </div>
                            {isLoading || "Надіслати"}
                          </button>
                        ) : (
                          <Button
                            sx={{
                              textTransform: "none",
                              fontFamily: "inherit",
                              fontWeigth: "600",
                              color: "#1E6D9A",
                              fontSize: "18px",
                              padding: "2px",
                            }}
                            type="submit"
                          >
                            Увійти
                          </Button>
                        )}
                      </div>
                    </form>
                  </Box>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
      {message && <Message>{message}</Message>}
    </>
  );
};
export const AddDoctorModal = ({ department }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const [formDataValues, setFormDataValues] = useState({
    doctorName: "",
    department: department,
    position: "",
    doctorImg: "",
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "doctorImg" && files.length > 0) {
      setImages([files[0]]);
    }
    setFormDataValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      Object.entries(formDataValues).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (images.length > 0) {
        formData.append("doctorImg", images[0]);
      }
      const result = dispatch(addDoctor(formData));

      if (result) {
        setFormDataValues({
          doctorName: "",
          department: department,
          position: "",
          doctorImg: "",
        });
        document.getElementById("addDoctorForm").reset();
        setIsLoading(false);
        successToast();
        handleClose();
      }
    } catch (error) {
      setIsLoading(false);
      errorToast();
    }
  };
  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          textTransform: "none",
          fontFamily: "inherit",
          fontWeigth: "600",
          color: "#1E6D9A",
          border: "1px solid #0FA7B0",
          fontSize: "20px",
        }}
      >
        +
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-5xl">
            Додаємо лікаря
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="addDoctorForm"
            className="flex flex-col gap-4 p-5"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              label="Імя та прізвище"
              variant="outlined"
              required
              size="small"
              name="doctorName"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Посада"
              variant="outlined"
              required
              size="small"
              name="position"
              onChange={handleInputChange}
            />
            <TextField
              type="file"
              variant="outlined"
              required
              size="small"
              name="doctorImg"
              onChange={handleInputChange}
            />
            <div className="self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <Button
                  sx={{
                    textTransform: "none",
                    fontFamily: "inherit",
                    fontWeigth: "600",
                    color: "#1E6D9A",
                    fontSize: "18px",
                    padding: "2px",
                  }}
                  type="submit"
                >
                  Додати
                </Button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
export const AdminAddDoctorModal = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const [formDataValues, setFormDataValues] = useState({
    doctorName: "",
    department: type,
    position: "",
    doctorImg: "",
  });

  const handleTypeChange = (event, newType) => {
    setType(newType);
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "doctorImg" && files.length > 0) {
      setImages([files[0]]);
    }
    setFormDataValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      Object.entries(formDataValues).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (images.length > 0) {
        formData.append("doctorImg", images[0]);
      }
      const result = dispatch(addDoctor(formData));

      if (result) {
        setFormDataValues({
          doctorName: "",
          department: "",
          position: "",
          doctorImg: "",
        });
        document.getElementById("addDoctorForm").reset();
        setIsLoading(false);
        successToast();
        handleClose();
      }
    } catch (error) {
      setIsLoading(false);
      errorToast();
    }
  };
  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          textTransform: "none",
          fontFamily: "inherit",
          fontWeigth: "600",
          color: "#1E6D9A",
          border: "1px solid #0FA7B0",
          fontSize: "20px",
        }}
      >
        +
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        className="overflow-y-scroll"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-5xl">
            Додаємо лікаря
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="addDoctorForm"
            className="flex flex-col gap-4 p-5"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              label="Імя та прізвище"
              variant="outlined"
              required
              size="small"
              name="doctorName"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Посада"
              variant="outlined"
              required
              size="small"
              name="position"
              onChange={handleInputChange}
            />
            <TextField
              type="file"
              variant="outlined"
              required
              size="small"
              name="doctorImg"
              onChange={handleInputChange}
            />
            <ToggleButtonGroup
              value={type}
              exclusive
              aria-label="Platform"
              onClick={handleInputChange}
              onChange={handleTypeChange}
              size="medium"
              orientation={isMobile ? "vertical" : "horizontal"}
            >
              <ToggleButton name={"department"} value="Приймальне">
                Приймальне
              </ToggleButton>
              <ToggleButton name={"department"} value="Хірургія">
                Хірургія
              </ToggleButton>
              <ToggleButton name={"department"} value="Лабораторія">
                Лабораторія
              </ToggleButton>
              <ToggleButton name={"department"} value="Інфекційне">
                Інфекційне
              </ToggleButton>
              <ToggleButton name={"department"} value="Поліклініка">
                Поліклініка
              </ToggleButton>
              <ToggleButton name={"department"} value="Пологове">
                Пологове
              </ToggleButton>
              <ToggleButton name={"department"} value="Травматологія">
                Травматологія
              </ToggleButton>
              <ToggleButton name={"department"} value="Терапія">
                Терапія
              </ToggleButton>
              <ToggleButton name={"department"} value="Неврологія">
                Неврологія
              </ToggleButton>
              <ToggleButton name={"department"} value="Реанімація">
                Реанімація
              </ToggleButton>
            </ToggleButtonGroup>
            <div className="self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <Button
                  sx={{
                    textTransform: "none",
                    fontFamily: "inherit",
                    fontWeigth: "600",
                    color: "#1E6D9A",
                    fontSize: "18px",
                    padding: "2px",
                  }}
                  type="submit"
                >
                  Додати
                </Button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
export const AddArticleModal = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [formDataValues, setFormDataValues] = useState({
    title: "",
    description: "",
    articleImg: "",
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "articleImg" && files.length > 0) {
      setImages([files[0]]);
    }
    setFormDataValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      Object.entries(formDataValues).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (images.length > 0) {
        formData.append("articleImg", images[0]);
      }
      const result = dispatch(addArticle(formData));
      if (result) {
        setFormDataValues({
          title: "",
          description: "",
          articleImg: "",
        });
        document.getElementById("addArticleForm").reset();
        setIsLoading(false);
        successToast();
        handleClose();
      }
    } catch (error) {
      setIsLoading(false);
      errorToast();
    }
  };
  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          textTransform: "none",
          fontFamily: "inherit",
          fontWeigth: "600",
          color: "#1E6D9A",
          border: "1px solid #0FA7B0",
          fontSize: "20px",
        }}
      >
        +
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-6 bg-white overflow-y-auto max-h-[90vh]">
          <OverlayScrollbarsComponent defer>
            <h1 className="flex justify-center  text-xl lg:text-3xl">
              Додаємо новину
            </h1>
            <button
              type="button"
              onClick={handleClose}
              className="absolute top-2 right-6"
            >
              <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
            </button>
            <form
              id="addArticleForm"
              className="flex flex-col gap-4 p-5 w-[400px]"
              onSubmit={handleSubmit}
            >
              <TextField
                type="text"
                label="Заголовок"
                variant="outlined"
                required
                size="small"
                name="title"
                onChange={handleInputChange}
              />
              <TextField
                type="text"
                label="Текст новини"
                variant="outlined"
                required
                size="small"
                name="description"
                multiline
                minRows="10"
                onChange={handleInputChange}
              />
              <TextField
                type="file"
                variant="outlined"
                required
                size="small"
                name="articleImg"
                onChange={handleInputChange}
              />
              <div className="self-center">
                {isLoading ? (
                  <button
                    type="submit"
                    className="button-black self-center pointer-events-none"
                  >
                    <div className="flex space-x-2 justify-center items-center">
                      <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                      <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                      <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                    </div>
                    {isLoading || "Надіслати"}
                  </button>
                ) : (
                  <Button
                    sx={{
                      textTransform: "none",
                      fontFamily: "inherit",
                      fontWeigth: "600",
                      color: "#1E6D9A",
                      fontSize: "18px",
                      padding: "2px",
                    }}
                    type="submit"
                  >
                    Додати
                  </Button>
                )}
              </div>
            </form>
          </OverlayScrollbarsComponent>
        </Box>
      </Modal>
    </div>
  );
};
export const AddDocumentModal = () => {
  const [type, setType] = useState("");
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const dispatch = useDispatch();
  const [formDataValues, setFormDataValues] = useState({
    title: "",
    documentDate: "",
    documentType: "",
    documentImg: "",
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "documentImg" && files.length > 0) {
      setImages([files[0]]);
    }
    setFormDataValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTypeChange = (event, newType) => {
    setType(newType);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      Object.entries(formDataValues).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (images.length > 0) {
        formData.append("documentImg", images[0]);
      }
      const result = dispatch(addDocument(formData));
      if (result) {
        setFormDataValues({
          title: "",
          documentDate: "",
          documentType: "",
          documentImg: "",
        });
        document.getElementById("addDocumentForm").reset();
        setIsLoading(false);
        successToast();
        handleClose();
      }
    } catch (error) {
      setIsLoading(false);
      errorToast();
    }
  };
  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          textTransform: "none",
          fontFamily: "inherit",
          fontWeigth: "600",
          color: "#1E6D9A",
          border: "1px solid #0FA7B0",
          fontSize: "20px",
        }}
      >
        +
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-5xl">
            Додаємо документ
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="addDocumentForm"
            className="flex flex-col gap-4 p-5"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              label="Назва документу"
              variant="outlined"
              required
              size="small"
              name="title"
              onChange={handleInputChange}
            />
            <TextField
              type="date"
              helpertext="дата документу"
              variant="outlined"
              required
              size="small"
              name="documentDate"
              onChange={handleInputChange}
            />

            <ToggleButtonGroup
              value={type}
              exclusive
              aria-label="Platform"
              onClick={handleInputChange}
              onChange={handleTypeChange}
              size="medium"
              orientation={isMobile ? "vertical" : "horizontal"}
            >
              <ToggleButton name={"documentType"} value="REPORTS">
                звіт
              </ToggleButton>
              <ToggleButton name={"documentType"} value="AWARDS">
                сертифікат
              </ToggleButton>
              <ToggleButton name={"documentType"} value="PROCUREMENTS">
                фінансовий плани
              </ToggleButton>
            </ToggleButtonGroup>
            <p className="text-center">Тип документу</p>
            <TextField
              type="file"
              variant="outlined"
              required
              size="small"
              name="documentImg"
              onChange={handleInputChange}
            />
            <div className="self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <Button
                  sx={{
                    textTransform: "none",
                    fontFamily: "inherit",
                    fontWeigth: "600",
                    color: "#1E6D9A",
                    fontSize: "18px",
                    padding: "2px",
                  }}
                  type="submit"
                >
                  Додати
                </Button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
export const AddUsefulInfoModal = () => {
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [formDataValues, setFormDataValues] = useState({
    usefulInfoLink: "",
    description: "",
    usefulInfoImg: "",
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "usefulInfoImg" && files.length > 0) {
      setImages([files[0]]);
    }
    setFormDataValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      Object.entries(formDataValues).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (images.length > 0) {
        formData.append("usefulInfoImg", images[0]);
      }
      const result = dispatch(addUsefulInfo(formData));
      if (result) {
        setFormDataValues({
          usefulInfoLink: "",
          description: "",
          usefulInfoImg: "",
        });
        document.getElementById("addUsefulInfo").reset();
        setIsLoading(false);
        successToast();
        handleClose();
      }
    } catch (error) {
      setIsLoading(false);
      errorToast();
    }
  };
  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          textTransform: "none",
          fontFamily: "inherit",
          fontWeigth: "600",
          color: "#1E6D9A",
          border: "1px solid #0FA7B0",
          fontSize: "20px",
        }}
      >
        +
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-5xl">
            Додаємо корисну інформацію
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="addUsefulInfo"
            className="flex flex-col gap-4 p-5"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              label="Текст"
              variant="outlined"
              multiline
              minRows="10"
              required
              size="small"
              name="description"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Посилання"
              helpertext="Посилання"
              variant="outlined"
              required
              size="small"
              name="usefulInfoLink"
              onChange={handleInputChange}
            />
            <TextField
              type="file"
              variant="outlined"
              required
              size="small"
              name="usefulInfoImg"
              onChange={handleInputChange}
            />
            <div className="self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <Button
                  sx={{
                    textTransform: "none",
                    fontFamily: "inherit",
                    fontWeigth: "600",
                    color: "#1E6D9A",
                    fontSize: "18px",
                    padding: "2px",
                  }}
                  type="submit"
                >
                  Додати
                </Button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
export const AddPaidServiceModal = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [formDataValues, setFormDataValues] = useState({
    title: "",
    price: "",
    code: "",
    category: type || "LABORATORY",
  });

  const handleTypeChange = (event, newType) => {
    setType(newType);
    setFormDataValues((prevData) => ({
      ...prevData,
      category: newType,
    }));
  };

  const formatPriceForBackend = (price) => {
    if (!price) return 0;
    const normalizedPrice = price.replace(",", ".");
    return Math.round(parseFloat(normalizedPrice) * 100);
  };

  const formatCodeForBackend = (code) => {
    const normalizedCode = code.replace(",", ".");
    const parts = normalizedCode.split(".");
    if (parts.length !== 2) return code;

    let decimalPart = parts[1];
    if (decimalPart.length === 1) decimalPart = "0" + decimalPart;

    return parts[0] + decimalPart;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim().replace(/\s+/g, " ");

    setFormDataValues((prevData) => ({
      ...prevData,
      [name]:
        name === "price"
          ? formatPriceForBackend(trimmedValue)
          : name === "code"
          ? Number(formatCodeForBackend(trimmedValue))
          : trimmedValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = dispatch(addPaidService(formDataValues));
      if (result) {
        setFormDataValues({
          title: "",
          price: "",
          code: "",
          category: "",
        });
        document.getElementById("addPaidService").reset();
        setIsLoading(false);
        successToast();
        handleClose();
        setType("");
      }
    } catch (error) {
      setIsLoading(false);
      errorToast();
    }
  };
  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          textTransform: "none",
          fontFamily: "inherit",
          fontWeigth: "600",
          color: "#1E6D9A",
          border: "1px solid #0FA7B0",
          fontSize: "20px",
        }}
      >
        +
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-5xl">
            Додаємо послугу
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="addPaidService"
            className="flex flex-col gap-4 p-5"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              label="Назва послуги"
              variant="outlined"
              required
              size="small"
              name="title"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Ціна"
              variant="outlined"
              required
              size="small"
              name="price"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="Код"
              variant="outlined"
              required
              size="small"
              name="code"
              onChange={handleInputChange}
            />
            <ToggleButtonGroup
              value={type}
              exclusive
              aria-label="Platform"
              onChange={handleTypeChange}
              size="medium"
              orientation={isMobile ? "vertical" : "horizontal"}
            >
              <ToggleButton name={"category"} value="LABORATORY">
                Лабораторні
              </ToggleButton>
              <ToggleButton name={"category"} value="DIAGNOSTIC">
                Діагностичні
              </ToggleButton>
              <ToggleButton name={"category"} value="CONSULTATIVE">
                Консультативні
              </ToggleButton>
              <ToggleButton name={"category"} value="MASSAGES">
                Масажі
              </ToggleButton>
              <ToggleButton name={"category"} value="EXAMINATIONS">
                Медичні огляди
              </ToggleButton>
            </ToggleButtonGroup>
            <div className="self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <Button
                  sx={{
                    textTransform: "none",
                    fontFamily: "inherit",
                    fontWeigth: "600",
                    color: "#1E6D9A",
                    fontSize: "18px",
                    padding: "2px",
                  }}
                  type="submit"
                >
                  Додати
                </Button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
export const PaymentDetailsModal = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          textTransform: "none",
          fontFamily: "inherit",
          fontWeigth: "600",
          color: "#1E6D9A",
          border: "1px solid #0FA7B0",
          fontSize: "20px",
        }}
      >
        Реквізити для оплати
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-[15%] lg:left-2/4 -translate-x-[10%] lg:-translate-x-1/2 -translate-y-1/2 rounded-[8px] py-10 px-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-3xl font-bold">
            Реквізити для оплати послуг
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <p className="self-start">
            КНП «Здолбунівська центральна міська лікарня»
          </p>
          <p className="self-start">UA95 305299 00000 26005040704762</p>
          <p className="self-start">Код ЄДРПОУ 02000105</p>
          <p className="self-start">
            Рівненська область, Рівненський район, місто Здолбунів, вул. Бандери
            С., 1
          </p>
          <p className="self-start">в АТ КБ «ПриватБанк»</p>
        </Box>
      </Modal>
    </div>
  );
};
export const CreateUserModal = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = dispatch(createUser(formData));
      if (result) {
        setFormData({
          name: "",
          email: "",
          role: "",
          password: "",
        });
        document.getElementById("addUserForm").reset();
        setIsLoading(false);
        successToast();
        handleClose();
      }
    } catch (error) {
      setIsLoading(false);
      errorToast();
    }
  };
  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          textTransform: "none",
          fontFamily: "inherit",
          fontWeigth: "600",
          color: "#1E6D9A",
          border: "1px solid #0FA7B0",
          fontSize: "20px",
        }}
      >
        +
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center text-xl lg:text-5xl">
            Створюємо юзера
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60 hover:opacity-100 hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <form
            id="addUserForm"
            className="flex flex-col gap-4 p-5 w-[400px]"
            onSubmit={handleSubmit}
          >
            <TextField
              type="text"
              label="name"
              variant="outlined"
              required
              size="small"
              name="name"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="email"
              variant="outlined"
              required
              size="small"
              name="email"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="role"
              variant="outlined"
              required
              size="small"
              name="role"
              onChange={handleInputChange}
            />
            <TextField
              type="text"
              label="password"
              variant="outlined"
              required
              size="small"
              name="password"
              onChange={handleInputChange}
            />

            <div className="self-center">
              {isLoading ? (
                <button
                  type="submit"
                  className="button-black self-center pointer-events-none"
                >
                  <div className="flex space-x-2 justify-center items-center">
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-grey rounded-full animate-bounce"></div>
                  </div>
                  {isLoading || "Надіслати"}
                </button>
              ) : (
                <Button
                  sx={{
                    textTransform: "none",
                    fontFamily: "inherit",
                    fontWeigth: "600",
                    color: "#1E6D9A",
                    fontSize: "18px",
                    padding: "2px",
                  }}
                  type="submit"
                >
                  Додати
                </Button>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
export const ConfirmDeleteArticleModal = ({ id }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(deleteArticle(id));
    handleClose();
    successToast();
  };
  return (
    <div>
      <button className="p-1 opacity-50" onClick={handleOpen}>
        <AiTwotoneDelete size={"25px"} />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center p-2 text-xl lg:text-5xl">
            Ви впевненні?
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60  hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <Button
            sx={{
              textTransform: "none",
              fontFamily: "inherit",
              fontWeigth: "600",
              color: "#1E6D9A",
              border: "1px solid #0FA7B0",
              fontSize: "20px",
            }}
            onClick={handleSubmit}
          >
            Підтвердити
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
export const ConfirmDeleteInfoModal = ({ id }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(deleteUsefulInfo(id));
    handleClose();
    successToast();
  };
  return (
    <div>
      <button className="p-1 opacity-50" onClick={handleOpen}>
        <AiTwotoneDelete size={"25px"} />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center p-2 text-xl lg:text-5xl">
            Ви впевненні?
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60  hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <Button
            sx={{
              textTransform: "none",
              fontFamily: "inherit",
              fontWeigth: "600",
              color: "#1E6D9A",
              border: "1px solid #0FA7B0",
              fontSize: "20px",
            }}
            onClick={handleSubmit}
          >
            Підтвердити
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
export const ConfirmDeleteServiceModal = ({ id }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(deletePaidService(id));
    handleClose();
    successToast();
  };
  return (
    <div>
      <button className="p-1 opacity-50" onClick={handleOpen}>
        <AiTwotoneDelete size={"25px"} />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center p-2 text-xl lg:text-5xl">
            Ви впевненні?
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60  hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <Button
            sx={{
              textTransform: "none",
              fontFamily: "inherit",
              fontWeigth: "600",
              color: "#1E6D9A",
              border: "1px solid #0FA7B0",
              fontSize: "20px",
            }}
            onClick={handleSubmit}
          >
            Підтвердити
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
export const ConfirmDeleteDoctorModal = ({ id }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(deleteDoctor(id));
    handleClose();
    successToast();
  };
  return (
    <div>
      <button className="p-1 opacity-50" onClick={handleOpen}>
        <AiTwotoneDelete size={"25px"} />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center p-2 text-xl lg:text-5xl">
            Ви впевненні?
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60  hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <Button
            sx={{
              textTransform: "none",
              fontFamily: "inherit",
              fontWeigth: "600",
              color: "#1E6D9A",
              border: "1px solid #0FA7B0",
              fontSize: "20px",
            }}
            onClick={handleSubmit}
          >
            Підтвердити
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
export const ConfirmDeleteUserModal = ({ id }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(deleteUser(id));
    handleClose();
    successToast();
  };
  return (
    <div>
      <button className="p-1 opacity-50" onClick={handleOpen}>
        <AiTwotoneDelete size={"25px"} />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center p-2 text-xl lg:text-5xl">
            Ви впевненні?
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60  hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <Button
            sx={{
              textTransform: "none",
              fontFamily: "inherit",
              fontWeigth: "600",
              color: "#1E6D9A",
              border: "1px solid #0FA7B0",
              fontSize: "20px",
            }}
            onClick={handleSubmit}
          >
            Підтвердити
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
export const ConfirmDeleteDocumentModal = ({ id }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(deleteDocument(id));
    handleClose();
    successToast();
  };
  return (
    <div>
      <button className="p-1 opacity-50" onClick={handleOpen}>
        <AiTwotoneDelete size={"25px"} />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-6 bg-white">
          <h1 className="flex self-center p-2 text-xl lg:text-5xl">
            Ви впевненні?
          </h1>
          <button
            type="button"
            onClick={handleClose}
            className="absolute top-2 right-3"
          >
            <CloseIcon className="opacity-60  hover:text-[#CA563F] [transition:0.3s_ease]" />
          </button>
          <Button
            sx={{
              textTransform: "none",
              fontFamily: "inherit",
              fontWeigth: "600",
              color: "#1E6D9A",
              border: "1px solid #0FA7B0",
              fontSize: "20px",
            }}
            onClick={handleSubmit}
          >
            Підтвердити
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
export const NewsModal = ({ article }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL || "https://zcml.com.ua/api";
  return (
    <div>
      <button className="p-1 opacity-50" onClick={handleOpen}>
        читати...
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute flex flex-col items-center gap-3 top-2/4 left-2/4 w-[350px] md:w-auto -translate-x-1/2 -translate-y-1/2 rounded-[8px] p-2 md:p-6 bg-white overflow-y-auto max-h-[90vh]">
          <OverlayScrollbarsComponent defer>
            <h2 className="flex self-center p-2 text-xl lg:text-3xl">
              {article.title}
            </h2>
            <button
              type="button"
              onClick={handleClose}
              className="absolute top-2 right-3"
            >
              <CloseIcon className="opacity-60  hover:text-[#CA563F] [transition:0.3s_ease]" />
            </button>
            <img
              src={`${BASE_URL}/${article.articleImg}`}
              alt="article_img"
              className="rounded-tl-[0] rounded-br-[12px] rounded-tr-[0] rounded-bl-[12px]"
            />
            <p className="flex flex-col indent-6">{article.description}</p>
          </OverlayScrollbarsComponent>
        </Box>
      </Modal>
    </div>
  );
};
