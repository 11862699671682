import { createSlice } from '@reduxjs/toolkit'
import { fetchAllPaidServices, addPaidService, deletePaidService } from './operations';

const initialState = {
    services: [],
    loading: false,
    error: null,
    count: 0,
    totalPages: 0,
    currentPage: 1,
}

export const documentsSlice = createSlice({
    name: 'services',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(fetchAllPaidServices.fulfilled, (state, { payload }) => {
                state.services = payload;
                state.loading = false;
                state.totalPages = Math.ceil(payload.count / 10);
                state.count = payload.count
            })
            .addCase(fetchAllPaidServices.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllPaidServices.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(addPaidService.fulfilled, (state, { payload }) => {
                state.services.push(payload);
                state.loading = false;
            })
            .addCase(addPaidService.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addPaidService.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(deletePaidService.fulfilled, (state, { payload }) => {
                state.services = state.services.filter((item) => item._id !== payload._id);
                state.loading = false;
            })
            .addCase(deletePaidService.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deletePaidService.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
    },
})

export default documentsSlice.reducer