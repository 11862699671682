import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import doctors from "./doctors/slice";
import articles from "./articles/slice";
import user from "./user/slice";
import documents from "./documents/slice";
import info from "./info/slice";
import services from "./services/slice"

const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: [], // 'token'
};


export const store = configureStore({
    reducer: {
        auth: persistReducer(authPersistConfig, user),
        doctors,
        articles,
        documents,
        info,
        services,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});
export const persistor = persistStore(store);