import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../user/operations';


export const fetchAllUsefulInfo = createAsyncThunk(
    'useful-info/fetchAll',
    async (page, thunkApi) => {
        try {
            const response = await instance.get(`/useful-info`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const addUsefulInfo = createAsyncThunk(
    'useful-info/add',
    async (body, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch user");
            }
            const result = await instance.post(`/useful-info`, body, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);

export const deleteUsefulInfo = createAsyncThunk(
    'useful-info/delete',
    async (id, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch user");
            }
            const result = await instance.delete(`/useful-info/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);